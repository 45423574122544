
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import userModule from "@/store/modules/user";
@Component
export default class EventList extends Vue {
  $message:any
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
      orderFieldName:"CreateDate",
      orderFieldDirection:"DESC"
    },
    className: "event",
  });

  subscriptionDataSource = new ListDataSource({
    className:"EventSubscription",
    config:{
      pageSize:100
    },
  })

  headers = [
    {
      "text": "ID",
      "value": "id",
      "sortable": true
    },
    {
      "text": "Дата создания",
      "value": "createDate",
      "sortable": true
    },
    {
      "text": "Подпись",
      "value": "caption",
      "sortable": true
    },
    {
      "text": "Статус",
      "value": "instanceStateId",
      "sortable": false
    },
    {
      "value": "subscribtion",
      "sortable": false,
    }
  ]

  folderConfig: any = {
    className: "eventFolder",
    rootFolder: "mainFolder",
  };

  getSubscriptionCaption(data:any){
    return this.subscriptionDataSource.items.some(s => s.eventId === data.id)
        ? "mdi-bell-ring" : "mdi-bell";
  }

  async subscribe(data:any){
    const subscription = this.subscriptionDataSource.items.find(s => s.eventId === data.id)
    if(!subscription){
      await this.subscriptionDataSource.add({
        eventId:data.id,
        userProfileId:userModule.profile.id,
      })
      this.$message("Подписка успешно оформлено");
      return;

    }
    await this.subscriptionDataSource.remove(subscription.id)
    this.$message("Подписка успешно отменена");
  }

  async mounted(){
    await this.subscriptionDataSource.get();
    this.subscriptionDataSource.config.filter = JSON.stringify([
      {
        fieldName: "userProfileId",
        fieldValue: userModule.profile.id
      }
    ])
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        disabled: true,
        exact: true,
      },
    ];
  }
}
